"use client";
import React, { useState } from "react";
import { arrayFAQ } from "@/constants/arrayFAQ";

type Props = {};

const faq_1 = (props: Props) => {
  const [expanded, setExpanded] = useState(null);

  const toggleFAQ = (index: any) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <section className="py-24 px-8 max-w-5xl mx-auto" id="faq">
      <div className="flex flex-col md:flex-row gap-12">
        <div className="flex flex-col text-left basis-1/2">
          <p className="inline-block font-semibold text-primary mb-4">FAQ</p>
          <h2 className="sm:text-4xl text-3xl font-extrabold text-base-content">
            Grâce à IOVIA, ne te pose plus ces questions !
          </h2>
        </div>
        <ul className="basis-1/2">
          {arrayFAQ.map((content, index) => (
            <li key={index}>
              <button
                className="relative flex gap-2 items-center w-full py-5 text-base font-semibold text-left border-t md:text-lg border-base-content/10"
                aria-expanded={expanded === index}
                onClick={() => toggleFAQ(index)}
                aria-label="Toggle FAQ: How secure is my insurance information?"
              >
                <span className="flex-1 text-lg text-base-content">
                  {content.title}
                </span>
                <svg
                  className="flex-shrink-0 w-4 h-4 ml-auto fill-current"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    y="7"
                    width="16"
                    height="2"
                    rx="1"
                    className={`transform origin-center transition duration-200 ease-out ${
                      expanded !== index ? "rotate-90" : ""
                    }`}
                  ></rect>
                  <rect
                    y="7"
                    width="16"
                    height="2"
                    rx="1"
                    className="transform origin-center transition duration-200 ease-out"
                  ></rect>
                </svg>
              </button>
              <div
                className={`transition-all duration-300 ease-in-out overflow-hidden ${
                  expanded === index ? "max-h-screen" : "max-h-0"
                }`}
                style={{ transition: "max-height 0.3s ease-in-out 0s" }}
              >
                <div className="pb-5 leading-relaxed">
                  <p className="space-y-2 leading-relaxed">
                    {content.description}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default faq_1;
